import { Button, Modal, Input, Form, Tag, message, Checkbox } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import customAxios from "../../utils/axios";

import { EditOutlined } from "@ant-design/icons";

import BarLoader from "react-spinners/BarLoader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Settings = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      bot_offline: "",
      country_blocked: "",
      user_banned: "",
      no_balance: "",
      start_message: ""
    }
  ]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const sendMessageOptions = useMemo(
    () => data.map((item) => ({ value: item.chat_id, label: item.username })).filter((item) => item.value !== null),
    [data]
  );
  const [messageApi, contextHolder] = message.useMessage();

  const formItemLayout = null;

  const columns = [
    {
      title: "Require Payments",
      description: "Enable or disable AI Responses charging the user for each response.",
      dataIndex: "payments_active",
      key: "payments_active",
      render: (text) => <Tag color={text.toString() == "1" ? "green" : "red"}>{text.toString() == "1" ? "Enabled" : "Disabled"}</Tag>
    },
    {
      title: "Send Voice Messages Overwrite",
      description: "Allows you to globably disable AI sending voice messages for all users. --No matter what their personal setting is--",
      dataIndex: "send_voice",
      key: "send_voice",
      render: (text) => <Tag color={text.toString() == "1" ? "green" : "red"}>{text.toString() == "1" ? "Enabled" : "Disabled"}</Tag>
    },
    {
      title: "Allow AI sending Images",
      description: "Allows you to globably disable AI sending images for all users. ",
      dataIndex: "allow_images_sending",
      key: "allow_images_sending",
      render: (text) => <Tag color={text.toString() == "1" ? "green" : "red"}>{text.toString() == "1" ? "Enabled" : "Disabled"}</Tag>
    },
    {
      title: "Asking for User bio in Onboarding Yes/No",
      description: "Enable or disable the user bio mode. When enabled, the user will be asked to provide a bio when they first start the bot. This will be used to generate more personalized responses.",
      dataIndex: "usrBio_mode",
      key: "usrBio_mode",
      render: (text) => <Tag color={text.toString() == "1" ? "green" : "red"}>{text.toString() == "1" ? "Enabled" : "Disabled"}</Tag>
    },
    {
      title: "Whitelist",
      description: "Enable or disable the whitelist mode. When enabled, only the users in the whitelist will be able to use the bot.",
      dataIndex: "whitelist_mode",
      key: "whitelist_mode",
      render: (text) => <Tag color={text.toString() == "1" ? "green" : "red"}>{text.toString() == "1" ? "Enabled" : "Disabled"}</Tag>
    },
    {
      title: "Single Influencer Mode",
      description: "Enable or disable the Single Influencer mode. When enabled, only the selected influencer will be available for the user.",
      dataIndex: "single_influencer_mode",
      key: "single_influencer_mode",
      render: (text) => <Tag color={text.toString() == "1" ? "green" : "red"}>{text.toString() == "1" ? "Enabled" : "Disabled"}</Tag>
    },
    {
      title: "Single Influencer Name",
      description: "The name of the influencer used in the Single Influencer mode.",
      dataIndex: "single_influencer_name",
      key: "single_influencer_name",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Elevenlabs API Key",
      dataIndex: "elevenlabs_API_KEY",
      key: "elevenlabs_API_KEY",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Elevenlabs API Key 2 (Optional)",
      dataIndex: "elevenlabs_API_KEY_2",
      key: "elevenlabs_API_KEY_2",
      render: (text) => <div>{text}</div>
    },
    {
      title: "ENABLE FIREWORKS",
      dataIndex: "ENABLE_FIREWORKS_API",
      key: "ENABLE_FIREWORKS_API",
      render: (text) => <Tag color={text.toString() == "1" ? "green" : "red"}>{text.toString() == "1" ? "Enabled" : "Disabled"}</Tag>
    },
    {
      title: "FIREWORKS MODEL",
      dataIndex: "FIREWORKS_MODEL",
      key: "FIREWORKS_MODEL",
      render: (text) => <div>{text}</div>
    },
    {
      title: "OpenAI API Key",
      dataIndex: "OPENAI_API_KEY",
      key: "OPENAI_API_KEY",
      render: (text) => <div>{text}</div>
    },
    {
      title: "FIREWORKS API Key",
      dataIndex: "FIREWORKS_API_KEY",
      key: "FIREWORKS_API_KEY",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Telegram Bot Token",
      dataIndex: "telegram_bot_token",
      key: "telegram_bot_token",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Instance Name",
      description: "The name of the instance. This will be used to identify the instance in the admin panel.",
      dataIndex: "instance_name",
      key: "instance_name",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Telegram Bot Name",
      description: "The name of the Telegram bot. This will be used to identify the bot in admin panel.",
      dataIndex: "telegram_bot_name",
      key: "telegram_bot_name",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Admin Email",
      description: "The email of the admin. This will be used to send notifications to the admin.",
      dataIndex: "admin_email",
      key: "admin_email",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Provider Token",
      description: "The stripe payment provider token.",
      dataIndex: "provider_token",
      key: "provider_token",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Stripe API Key",
      description: "The stripe API key.",
      dataIndex: "stripe_api_key",
      key: "stripe_api_key",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Wait List From",
      description: "Wait List from hours interval",
      dataIndex: "wait_list_from",
      key: "wait_list_from",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Wait List Until",
      description: "Wait List until hours interval",
      dataIndex: "wait_list_until",
      key: "wait_list_until",
      render: (text) => <div>{text}</div>
    },
    {
      title: "PWA Signup Bonus Token Amount",
      description: "The amount of bonus the user will receive when they sign up using the PWA app.",
      dataIndex: "pwa_signup_bonus_amount",
      key: "pwa_signup_bonus_amount",
      render: (text) => <div>{text}</div>
    },
    {
      title: "PWA Bonus Message Success",
      description: "Show the message when the user successfully install PWA app and receive bonus",
      dataIndex: "pwa_bonus_message_success",
      key: "pwa_bonus_message_success",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Image Generation Banned Words Global (will be appended with the influencer specific banned words)",
      dataIndex: "image_generation_banned_words_global",
      key: "image_generation_banned_words_global",
      render: (text) => <div>{text}</div>
    }

    // {
    //   title: "Action",
    //   key: "action",
    //   width: "10%",
    //   render: (text, record) => (
    //     <div style={{ display: "flex", gap: "0.5rem" }}>
    //       <Button
    //         type="primary"
    //         danger
    //         onClick={() => {
    //           editDetail(record);
    //         }}
    //       >
    //         <EditOutlined />
    //       </Button>
    //     </div>
    //   )
    // }
  ];

  function editDetail(record) {
    // console.log("here is record", record);
    form.setFieldsValue({
      payments_active: record.payments_active,
      send_voice: record.send_voice,
      allow_images_sending: record.allow_images_sending,
      usrBio_mode: record.usrBio_mode,
      whitelist_mode: record.whitelist_mode,
      elevenlabs_API_KEY: record.elevenlabs_API_KEY,
      OPENAI_API_KEY: record.OPENAI_API_KEY,
      FIREWORKS_API_KEY: record.FIREWORKS_API_KEY,
      ENABLE_FIREWORKS_API: record.ENABLE_FIREWORKS_API,
      FIREWORKS_MODEL: record.FIREWORKS_MODEL,
      telegram_bot_token: record.telegram_bot_token,
      instance_name: record.instance_name,
      telegram_bot_name: record.telegram_bot_name,
      single_influencer_mode: record.single_influencer_mode,
      single_influencer_name: record.single_influencer_name,
      admin_email: record.admin_email,
      provider_token: record.provider_token,
      stripe_api_key: record.stripe_api_key,
      wait_list_from: record.wait_list_from,
      wait_list_until: record.wait_list_until,
      pwa_bonus_message_success: record.pwa_bonus_message_success,
      image_generation_banned_words_global: record.image_generation_banned_words_global,
      pwa_signup_bonus_amount: record.pwa_signup_bonus_amount,
      elevenlabs_API_KEY_2: record.elevenlabs_API_KEY_2
    });
    showEditMsgModal();
  }

  useEffect(() => {
    getMsgData();
  }, []);

  const getMsgData = () => {
    customAxios.get(SERVER_URL + "/system/settings").then(function (res) {
    // console.log("res", res.data);
      setData(res.data);
      setLoading(false);
    });
  };

  const showEditMsgModal = () => {
    setIsEditModalOpen(true);
  };

  const handleMsgOk = async () => {
    try {
      const messages = form.getFieldsValue();
      console.log("messages", messages);
      if (messages.wait_list_from < 1) {
        messageApi.error("Wait List From hours interval should be greater than 0");
        return;
      }
      if (messages.wait_list_until < 2) {
        messageApi.error("Wait List Until hours interval should be greater than 1");
        return;
      }
      const { data } = await customAxios.put(SERVER_URL + "/system/settings", { ...messages });
      setData(data);
      setIsEditModalOpen(false);
      clearFormData();
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };
  const handleUserCancel = () => {
    setIsEditModalOpen(false);
    clearFormData();
  };
  function clearFormData() {
    form.resetFields();
  }

  return (
    <div>
      {contextHolder}
      <div>
      </div>
        {loading &&
          <BarLoader color="#1677ff" 
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        }
        {!loading &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {columns.map((column) => (
              <div key={column.key} style={{ margin: '10px', padding: '10px', border: '1px solid gray' }}>
                <h3>{column.title}</h3>
                <p><i>{column.description}</i></p>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {data.map((item) => (
                    <li key={item.key}>{column.render(item[column.dataIndex])}</li>
                  ))}
                </ul>
              </div>
            ))}
            <div style={{ margin: '10px', padding: '10px' }}>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {data.map((item) => (
                  <li key={item.key} style={{ margin: '5px' }}>
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        editDetail(item);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }

      <Modal
        title={"Edit user" }
        open={isEditModalOpen}
        onOk={handleMsgOk}
        onCancel={handleUserCancel}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name={"payments_active"}
            label="Require Payments"
            rules={[{ required: true }]}
          >
            <select className="form-control" name="payments_active" id="payments_active">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={"send_voice"}
            label="Send Voice Messages"
            rules={[{ required: true, message: "Please select wether Voice Messages should be sent to the user or just text" }]}
          >
            <select className="form-control" name="send_voice" id="send_voice">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={"allow_images_sending"}
            label="Allow Images"
            rules={[{ required: true, message: "Please select wether Images should be sent to the user or not" }]}
          >
            <select className="form-control" name="allow_images_sending" id="allow_images_sending">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="usrBio_mode"
            label="User Bio enabeld in Onboarding"
            rules={[{ required: true, message: "Please select wether onboarding should ask for a usr bio or not" }]}
          >
            <select className="form-control" name="usrBio_mode" id="usrBio_mode">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="whitelist_mode"
            label="Whitelist"
          >
            <select className="form-control" name="whitelist_mode" id="whitelist_mode">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="single_influencer_mode"
            label="Single Influencer Mode"
          >
            <select className="form-control" name="single_influencer_mode" id="single_influencer_mode">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="single_influencer_name"
            label="Single Influencer Name"
          >
            <Input placeholder="Single Influencer Name" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="elevenlabs_API_KEY"
            label="Elevenlabs API Key"
          >
            <Input placeholder="Elevenlabs API Key" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="elevenlabs_API_KEY_2"
            label="Elevenlabs API Key 2 (Optional)"
          >
            <Input placeholder="Elevenlabs API Key 2 (Optional)" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="ENABLE_FIREWORKS_API"
            label="ENABLE FIREWORKS API"
          >
            <select className="form-control" name="ENABLE_FIREWORKS_API" id="ENABLE_FIREWORKS_API">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="FIREWORKS_MODEL"
            label="Fireworks Model"
          >
            <Input placeholder="Fireworks Model" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="OPENAI_API_KEY"
            label="OpenAI API Key"
          >
            <Input placeholder="OpenAI API Key" />
          </Form.Item>
          <Form.Item
            {...formItemLayout} 
            name="FIREWORKS_API_KEY"
            label="FIREWORKS API Key"
          >
            <Input placeholder="FIREWORKS API Key" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="telegram_bot_token"
            label="Telegram Bot Token"
          >
            <Input placeholder="Telegram Bot Token" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="instance_name"
            label="Instance Name"
          >
            <Input placeholder="Production Instance" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="telegram_bot_name"
            label="Telegram Bot Name"
          >
            <Input placeholder="@telegram_bot" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="admin_email"
            label="Admin Email"
          >
            <Input placeholder="Admin Email" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="provider_token"
            label="Provider Token"
          >
            <Input placeholder="Provider Token" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="stripe_api_key"
            label="Stripe API Key"
          >
            <Input placeholder="Stripe API Key" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="wait_list_from"
            label="Wait List From"
          >
            <Input type="number" placeholder="Wait List From hours inteval" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="wait_list_until"
            label="Wait List Until"
          >
            <Input type="number" placeholder="Wait List Until hours interval" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="pwa_signup_bonus_amount"
            label="PWA Signup Bonus Token Amount"
          >
            <Input type="number" placeholder="The amount of bonus the user will receive when they sign up using the PWA app" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="pwa_bonus_message_success"
            label="PWA Bonus Message Success"
          >
            <Input type="text" placeholder="Show the message when the user successfully install PWA app and receive bonus" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="image_generation_banned_words_global"
            label="Image Generation Banned Words Global (will be appended with the influencer specific banned words)"
          >
            <Input type="text" placeholder="Image Generation Banned Words Global" />
          </Form.Item>
         
        </Form>
      </Modal>
    </div>
  );
};

export default Settings;