import {
  Table,
  Button,
  Input,
  Form,
  message,
  Select,
  Space,
  Collapse,
  Upload,
  Card,
} from "antd";
import React, { useState, useEffect } from "react";
import customAxios from "../../utils/axios";

import { useLocation } from "react-router";

import BarLoader from "react-spinners/BarLoader";
import BasicFunctionalities from "./BasicFunctionalities";
import SecretMenu from "./SecretMenu";
import TipSettings from "./TipSettings";
import WishlistSettings from "./WishlistSettings";
import ModelSettings from "./ModelSettings";
import AstriaImageGenerationSettings from "./AstriaImageGenerationSettings";
import SeaArtImageGenerationSettings from "./SeaArtImageGenerationSettings";
import DiffusImageGenerationSettings from "./DiffusImageGenerationSettings";
import ImageGenerationServices from "./ImageGenerationServices";
import VoiceGenerationSettings from "./VoiceGenerationSettings";
import PrescriptiveMode from "./PrescriptiveMode";
import BonusContent from "./BonusContent";
import RegenerationMessageModeSettings from "./RegenerationMessageModeSettings";
import { UploadOutlined } from "@ant-design/icons";

const formItemLayout = null;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const InfluencerSettings = () => {
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const influencerId = location.pathname.split("/")[2];
  const [form] = Form.useForm();

  const [fileInput, setFileInput] = useState(null);
  const [fileUploadPath, setFileUploadPath] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [fileInput1, setFileInput1] = useState([]);
  const [fileUploadPath1, setFileUploadPath1] = useState("");
  const [fileUploadLoading1, setFileUploadLoading1] = useState(false);

  const [fileInput2, setFileInput2] = useState([]);
  const [fileUploadPath2, setFileUploadPath2] = useState("");
  const [fileUploadLoading2, setFileUploadLoading2] = useState(false);

  const [fileInput3, setFileInput3] = useState([]);
  const [fileUploadPath3, setFileUploadPath3] = useState("");
  const [fileUploadLoading3, setFileUploadLoading3] = useState(false);

  const [fileInput4, setFileInput4] = useState([]);
  const [fileUploadPath4, setFileUploadPath4] = useState("");
  const [fileUploadLoading4, setFileUploadLoading4] = useState(false);

  const [fileInput5, setFileInput5] = useState([]);
  const [fileUploadPath5, setFileUploadPath5] = useState("");
  const [fileUploadLoading5, setFileUploadLoading5] = useState(false);

  const [fileInput6, setFileInput6] = useState(null);
  const [fileUploadPath6, setFileUploadPath6] = useState("");
  const [fileUploadLoading6, setFileUploadLoading6] = useState(false);

  const [fileInput7, setFileInput7] = useState(null);
  const [fileUploadPath7, setFileUploadPath7] = useState("");
  const [fileUploadLoading7, setFileUploadLoading7] = useState(false);

  const [fileInput8, setFileInput8] = useState(null);
  const [fileUploadPath8, setFileUploadPath8] = useState("");
  const [fileUploadLoading8, setFileUploadLoading8] = useState(false);

  const [prohibitedTopics, setProhibitedTopics] = useState([]);

  const [isSettings, setIsSettings] = useState(influencerId);

  const [enable_moderation_engine, setEnable_moderation_engine] = useState("0");
  const [enable_intent_engine, setEnable_intent_engine] = useState("0");

  const [messageApi, contextHolder] = message.useMessage();

  const [imageServicesData, setImageServicesData] = useState([]);

  const [aiEngine, setAiEngine] = useState("mistral");

  const [data, setData] = useState([
    {
      id: "",
      name: "",
      server_ip: "",
      character: "",
      voice_id: "",
      voice_id_2: "",
      prohibited_topics: "",
      temperature: "",
      top_p: "",
      top_k: "",
      min_length: "",
      max_new_tokens: "",
      notification_frequency: "",
      notification_message: "",
      tip_pre_message: "",
      secret_menu_titles: "",
      secret_menu_links: "",
    },
  ]);

  const handleSettingsOk = async () => {
    try {
      const formData = form.getFieldsValue();
      formData.prohibitedTopics = prohibitedTopics;

      if (fileInput !== null && fileUploadPath == "") {
        alert("File selected but not uploaded");
        return;
      }
      formData.profilePicPath = fileUploadPath;

      // formData.notification_image_1 = fileUploadPath6;
      // formData.notification_image_2 = fileUploadPath7;
      // formData.notification_image_3 = fileUploadPath8;

      let welcomeMessages = "";
      welcomeMessages += '"' + formData.welcome_message1 + '",';
      welcomeMessages += '"' + formData.welcome_message2 + '",';
      welcomeMessages += '"' + formData.welcome_message3 + '",';
      welcomeMessages += '"' + formData.welcome_message4 + '",';
      welcomeMessages += '"' + formData.welcome_message5 + '"';
      formData.welcome_messages = welcomeMessages;

      let welcome_messages_images = "";
      welcome_messages_images += '"' + fileUploadPath1 + '",';
      welcome_messages_images += '"' + fileUploadPath2 + '",';
      welcome_messages_images += '"' + fileUploadPath3 + '",';
      welcome_messages_images += '"' + fileUploadPath4 + '",';
      welcome_messages_images += '"' + fileUploadPath5 + '"';
      formData.welcome_messages_images = welcome_messages_images;

      formData.twitter_preview_pic = fileUploadPath6;

      let secretMenuTitles = "";
      secretMenuTitles += '"' + formData.secret_menu_title1 + '",';
      secretMenuTitles += '"' + formData.secret_menu_title2 + '",';
      secretMenuTitles += '"' + formData.secret_menu_title3 + '",';
      secretMenuTitles += '"' + formData.secret_menu_title4 + '"';
      formData.secret_menu_titles = secretMenuTitles;

      let secretMenuLinks = "";
      secretMenuLinks += '"' + formData.secret_menu_link1 + '",';
      secretMenuLinks += '"' + formData.secret_menu_link2 + '",';
      secretMenuLinks += '"' + formData.secret_menu_link3 + '",';
      secretMenuLinks += '"' + formData.secret_menu_link4 + '"';
      formData.secret_menu_links = secretMenuLinks;

      const joinedCharacterContext =
        formData.system_prompt?.length > 0 && formData.character_context_vllm?.length > 0
          ? `${formData.system_prompt.trim()}\nCHARACTER: ${formData.character_context_vllm} ENDCHARACTER`
          : formData.character_context_vllm + ' ';
      formData.character_context_vllm = joinedCharacterContext || "";
      delete formData.system_prompt;

      formData.use_openAI = formData.ai_engine === "llama" ? formData.use_openAI : "0";

      const { data } = await customAxios.put(
        SERVER_URL + "/influencer/settings",
        { ...formData, id: isSettings }
      );
      setData(data.filter((influencer) => influencer.id == influencerId));

      messageApi.success("Settings Updated");

      setFileUploadLoading(false);
      setFileUploadLoading1(false);
      setFileUploadLoading2(false);
      setFileUploadLoading3(false);
      setFileUploadLoading4(false);
      setFileUploadLoading5(false);
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  function editSettings(record) {
    // console.log("Moderation Engine.", record.enable_moderation_engine)

    if (record.welcome_messages == null || record.welcome_messages == "") {
      record.welcome_messages = '","","","';
    }

    if (
      record.welcome_messages_images == null ||
      record.welcome_messages_images == ""
    ) {
      record.welcome_messages_images = '","","","';
    }

    setIsSettings(record.id);
    form.setFieldsValue({
      temperature: record.temperature,
      top_p: record.top_p,
      top_k: record.top_k,
      min_length: record.min_length,
      max_new_tokens: record.max_new_tokens,
      prohibited_topics: record.prohibited_topics.replace(/"/g, ""),
      welcome_message1: record.welcome_messages.split('",')[0].slice(1) || "",
      welcome_message2: record.welcome_messages.split('",')[1].slice(1) || "",
      welcome_message3: record.welcome_messages.split('",')[2].slice(1) || "",
      welcome_message4: record.welcome_messages.split('",')[3].slice(1) || "",
      welcome_message5: (record.welcome_messages.split('",')[4] || "")
        .slice(1)
        .slice(0, -1),
      welcome_messages_images1:
        record.welcome_messages_images.split('",')[0].slice(1) || "",
      welcome_messages_images2:
        record.welcome_messages_images.split('",')[1].slice(1) || "",
      welcome_messages_images3:
        record.welcome_messages_images.split('",')[2].slice(1) || "",
      welcome_messages_images4:
        record.welcome_messages_images.split('",')[3].slice(1) || "",
      welcome_messages_images5: (
        record.welcome_messages_images.split('",')[4] || ""
      )
        .slice(1)
        .slice(0, -1),
      flag_sexual: record.flag_sexual,
      flag_hate: record.flag_hate,
      flag_harassment: record.flag_harassment,
      flag_self_harm: record.flag_self_harm,
      flag_sexual_minors: record.flag_sexual_minors,
      flag_hate_threatening: record.flag_hate_threatening,
      flag_violence_graphic: record.flag_violence_graphic,
      flag_flag_self_harm_intent: record.flag_flag_self_harm_intent,
      flag_self_harm_instructions: record.flag_self_harm_instructions,
      flag_harassment_threatening: record.flag_harassment_threatening,
      flag_violence: record.flag_violence,
      character_context_vllm: record.character_context_vllm?.split('CHARACTER: ')?.[1]?.replace(' ENDCHARACTER', '') || "",
      system_prompt: record.character_context_vllm?.split('CHARACTER: ')?.[0] || "",
      character_context_openAI: record.character_context_openAI,
      usr_model_role: record.usr_model_role,
      bot_model_role: record.bot_model_role,
      usr_in_text_model_role: record.usr_in_text_model_role,
      bot_in_text_model_role: record.bot_in_text_model_role,
      enable_moderation_engine: record.enable_moderation_engine,
      enable_intent_engine: record.enable_intent_engine,
      flag_past_reputation_damage: record.flag_past_reputation_damage,
      flag_controversial: record.flag_controversial,
      flag_politics: record.flag_politics,
      flag_religion: record.flag_religion,
      stopping_strings: record.stopping_strings,
      use_openAI: record.ai_engine === "llama" ? record.use_openAI : "0",
      presence_penalty: record.presence_penalty,
      prompt_sexual: record.prompt_sexual,
      prompt_hate: record.prompt_hate,
      prompt_harassment: record.prompt_harassment,
      prompt_self_harm: record.prompt_self_harm,
      prompt_hate_threatening: record.prompt_hate_threatening,
      prompt_violence_graphic: record.prompt_violence_graphic,
      prompt_flag_self_harm_intent: record.prompt_flag_self_harm_intent,
      prompt_self_harm_instructions: record.prompt_self_harm_instructions,
      prompt_harassment_threatening: record.prompt_harassment_threatening,
      prompt_violence: record.prompt_violence,
      prompt_past_reputation_damage: record.prompt_past_reputation_damage,
      prompt_controversial: record.prompt_controversial,
      prompt_politics: record.prompt_politics,
      prompt_religion: record.prompt_religion,
      prompt_sexual_minors: record.prompt_sexual_minors,
      tip_pre_message: record.tip_pre_message,
      secret_menu_title1:
        record.secret_menu_titles.split('",')[0].slice(1) || "",
      secret_menu_title2: (
        record.secret_menu_titles.split('",')[1] || ""
      ).slice(1),
      secret_menu_title3:
        (record.secret_menu_titles.split('",')[2] || "").slice(1) || "",
      secret_menu_title4:
        (record.secret_menu_titles.split('",')[3] || "")
          .slice(1)
          .slice(0, -1) || "",
      secret_menu_link1:
        (record.secret_menu_links.split('",')[0] || "").slice(1) || "",
      secret_menu_link2:
        (record.secret_menu_links.split('",')[1] || "").slice(1) || "",
      secret_menu_link3:
        (record.secret_menu_links.split('",')[2] || "").slice(1) || "",
      secret_menu_link4:
        (record.secret_menu_links.split('",')[3] || "").slice(1).slice(0, -1) ||
        "",
      no_wishlist_message: record.no_wishlist_message,
      wishlist_pre_message: record.wishlist_pre_message,
      wishlist_link: record.wishlist_link,
      wishlist_post_message: record.wishlist_post_message,
      regeneration_mode_enabled: record.regeneration_mode_enabled,
      open_ai_timeout: record.open_ai_timeout,
      token_limit: record.token_limit,
      loraModel: record.loraModel,
      no_custom_image_message: record.no_custom_image_message,
      custom_image_pre_message: record.custom_image_pre_message,
      custom_image_post_message: record.custom_image_post_message,
      custom_image_generating_message: record.custom_image_generating_message,
      visible: record.visible,
      inpainting_active: record.inpainting_active,
      face_correct_active: record.face_correct_active,
      super_resolution_active: record.super_resolution_active,
      image_generation_suffix: record.image_generation_suffix,
      hires_fix_active: record.hires_fix_active,
      negative_prompt_image_generation: record.negative_prompt_image_generation,
      imageGenerationResolution: record.imageGenerationResolution,
      imageGenerationTimeout: record.imageGenerationTimeout,
      imageGeneration_token_classname: record.imageGeneration_token_classname,
      imageGenerationErrorMSG: record.imageGenerationErrorMSG,
      astriaURL: record.astriaURL,
      allowImageSending: record.allowImageSending,
      allowLinkSending: record.allowLinkSending,
      prescriptive_mode_enabled: record.prescriptive_mode_enabled,
      prescriptive_mode_data: record.prescriptive_mode_data,
      prescriptive_mode_delay: record.prescriptive_mode_delay,
      voice_similarity_boost: record.voice_similarity_boost,
      voice_stability: record.voice_stability,
      voice_style: record.voice_style,
      voice_use_speaker_boost: record.voice_use_speaker_boost,
      imageGenerationScheduler: record.imageGenerationScheduler,
      imageGenerationFilm_grain_active: record.imageGenerationFilm_grain_active,
      custom_image_img_amount_message: record.custom_image_img_amount_message,
      imageGeneration_steps: record.imageGeneration_steps,
      imageGeneration_face_swap_active: record.imageGeneration_face_swap_active,
      regenerationMessage_mode_enabled: record.regenerationMessage_mode_enabled,
      regenerationMessage_mode_message: record.regenerationMessage_mode_message,
      customPic_hint_message: record.customPic_hint_message,
      usersent_noCaption_Image_message: record.usersent_noCaption_Image_message,
      startOver_hintMSG: record.startOver_hintMSG,
      characterExcluded_hintMSG: record.characterExcluded_hintMSG,
      imageGeneration_token_classname_user_placeholders:
        record.imageGeneration_token_classname_user_placeholders,
      notification_frequency: record.notification_frequency,
      notification_frequency_2: record.notification_frequency_2,
      notification_frequency_3: record.notification_frequency_3,
      notification_message: record.notification_message,
      notification_message_2: record.notification_message_2,
      notification_message_3: record.notification_message_3,
      notification_title_1: record.notification_title_1,
      notification_title_2: record.notification_title_2,
      notification_title_3: record.notification_title_3,
      // notification_image_1: record.notification_image_1,
      // notification_image_2: record.notification_image_2,
      // notification_image_3: record.notification_image_3,
      // notification_prescriptive_mode_1: record.notification_prescriptive_mode_1,
      // notification_prescriptive_mode_2: record.notification_prescriptive_mode_2,
      // notification_prescriptive_mode_3: record.notification_prescriptive_mode_3,
      // notification_prompt_1: record.notification_prompt_1,
      // notification_prompt_2: record.notification_prompt_2,
      // notification_prompt_3: record.notification_prompt_3,
      imageGeneration_cfg_scale: record.imageGeneration_cfg_scale,
      seaart_imageGeneration_category: record.seaart_imageGeneration_category,
      seaart_imageGeneration_art_model_no:
        record.seaart_imageGeneration_art_model_no,
      seaart_imageGeneration_sample_name:
        record.seaart_imageGeneration_sample_name,
      seaart_imageGeneration_cfg_scale: record.seaart_imageGeneration_cfg_scale,
      seaart_imageGeneration_steps: record.seaart_imageGeneration_steps,
      seaart_imageGeneration_restore_face:
        record.seaart_imageGeneration_restore_face,
      seaart_imageGeneration_negative_prompt:
        record.seaart_imageGeneration_negative_prompt,
      seaart_imageGeneration_vae: record.seaart_imageGeneration_vae,
      seaart_imageGeneration_model_id: record.seaart_imageGeneration_model_id,
      seaart_imageGeneration_model_weight:
        record.seaart_imageGeneration_model_weight,
      diffus_imageGeneration_sampler_name:
        record.diffus_imageGeneration_sampler_name,
      diffus_imageGeneration_cfg_scale: record.diffus_imageGeneration_cfg_scale,
      diffus_imageGeneration_steps: record.diffus_imageGeneration_steps,
      diffus_imageGeneration_restore_face:
        record.diffus_imageGeneration_restore_face,
      diffus_imageGeneration_negative_prompt:
        record.diffus_imageGeneration_negative_prompt,
      diffus_imageGeneration_clip_skip: record.diffus_imageGeneration_clip_skip,
      diffus_imageGeneration_model_name:
        record.diffus_imageGeneration_model_name,
      diffus_imageGeneration_n_iter: record.diffus_imageGeneration_n_iter,
      diffus_imageGeneration_enable_hr: record.diffus_imageGeneration_enable_hr,
      diffus_imageGeneration_denoising_strength:
        record.diffus_imageGeneration_denoising_strength,
      diffus_imageGeneration_hr_scale: record.diffus_imageGeneration_hr_scale,
      diffus_imageGeneration_hr_upscaler:
        record.diffus_imageGeneration_hr_upscaler,
      diffus_imageGeneration_hr_second_pass_steps:
        record.diffus_imageGeneration_hr_second_pass_steps,
      diffus_imageGeneration_hr_resize_x:
        record.diffus_imageGeneration_hr_resize_x,
      diffus_imageGeneration_hr_resize_y:
        record.diffus_imageGeneration_hr_resize_y,
      diffus_imageGeneration_hr_sampler_name:
        record.diffus_imageGeneration_hr_sampler_name,
      diffus_imageGeneration_hr_prompt: record.diffus_imageGeneration_hr_prompt,
      diffus_imageGeneration_hr_negative_prompt:
        record.diffus_imageGeneration_hr_negative_prompt,
      diffus_imageGeneration_adetailer_model_1:
        record.diffus_imageGeneration_adetailer_model_1,
      diffus_imageGeneration_adetailer_prompt_1:
        record.diffus_imageGeneration_adetailer_prompt_1,
      diffus_imageGeneration_adetailer_model_2:
        record.diffus_imageGeneration_adetailer_model_2,
      diffus_imageGeneration_adetailer_prompt_2:
        record.diffus_imageGeneration_adetailer_prompt_2,
      diffus_imageGeneration_adetailer_negative_prompt_2:
        record.diffus_imageGeneration_adetailer_negative_prompt_2,
      diffus_imageGeneration_adetailer_negative_prompt_1:
        record.diffus_imageGeneration_adetailer_negative_prompt_1,
      sfw_image_service: record.sfw_image_service,
      nsfw_image_service: record.nsfw_image_service,
      diffus_imageGeneration_token_classname:
        record.diffus_imageGeneration_token_classname,
      diffus_imageGeneration_prompt_prefix:
        record.diffus_imageGeneration_prompt_prefix,
      diffus_imageGeneration_prompt_suffix:
        record.diffus_imageGeneration_prompt_suffix,
      image_service_type_id: record.image_service_type_id,
      image_generation_banned_words: record.image_generation_banned_words,
      image_generation_prompt_sfw_placeholder:
        record.image_generation_prompt_sfw_placeholder,
      astria_imageGeneration_backend_version:
        record.astria_imageGeneration_backend_version,
      mistralInterpretationTimeout: record.mistralInterpretationTimeout,
      pwa_hint_msg: record.pwa_hint_msg,
      notifications_hint_msg: record.notifications_hint_msg,
      coming_soon_mode: record.coming_soon_mode,
      coming_soon_msg: record.coming_soon_msg,
      mistral_interpreation_prompt: record.mistral_interpreation_prompt,
      twitter_preview_pic: record.twitter_preview_pic,
      noVoiceErrorMsg: record.noVoiceErrorMsg,
      bonusContent_hint_msgs: record.bonusContent_hint_msgs,
      ai_engine: record.ai_engine,
    });

    setAiEngine(record.ai_engine || "mistral");

    setFileUploadPath(record.profilePic);
    const tempPath1 =
      record.welcome_messages_images.split('",')[0].slice(1) || "";
    setFileUploadPath1(tempPath1);
    if (tempPath1.length > 0) {
      setFileInput1([
        { uid: tempPath1, name: tempPath1, status: "done", url: tempPath1 },
      ]);
    }
    const tempPath2 =
      record.welcome_messages_images.split('",')[1].slice(1) || "";
    setFileUploadPath2(tempPath2);
    if (tempPath2.length > 0) {
      setFileInput2([
        { uid: tempPath2, name: tempPath2, status: "done", url: tempPath2 },
      ]);
    }
    const tempPath3 =
      record.welcome_messages_images.split('",')[2].slice(1) || "";
    setFileUploadPath3(tempPath3);
    if (tempPath3.length > 0) {
      setFileInput3([
        { uid: tempPath3, name: tempPath3, status: "done", url: tempPath3 },
      ]);
    }
    const tempPath4 =
      record.welcome_messages_images.split('",')[3].slice(1) || "";
    setFileUploadPath4(tempPath4);
    if (tempPath4.length > 0) {
      setFileInput4([
        { uid: tempPath4, name: tempPath4, status: "done", url: tempPath4 },
      ]);
    }
    const tempPath5 =
      (record.welcome_messages_images.split('",')[4] || "")
        .slice(1)
        .slice(0, -1) || "";
    setFileUploadPath5(tempPath5);
    if (tempPath5.length > 0) {
      setFileInput5([
        { uid: tempPath5, name: tempPath5, status: "done", url: tempPath5 },
      ]);
    }

    setFileUploadPath6(record.twitter_preview_pic || "");
    if ((record.twitter_preview_pic || "").length > 0) {
      setFileInput6([
        {
          uid: record.twitter_preview_pic,
          name: record.twitter_preview_pic,
          status: "done",
          url: record.twitter_preview_pic,
        },
      ]);
    }

    // setFileUploadPath6(record.notification_image_1);
    // setFileUploadPath7(record.notification_image_2);
    // setFileUploadPath8(record.notification_image_3);

    setEnable_moderation_engine(record.enable_moderation_engine);
    setEnable_intent_engine(record.enable_intent_engine);
  }

  const influencerColumns = [
    {
      title: "UUID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Server Ip",
      dataIndex: "server_ip",
      key: "server_ip",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Character",
      dataIndex: "character",
      key: "character",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Voice Id",
      dataIndex: "voice_id",
      key: "voice_id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Voice Id 2 (for Elevenlabs API Key 2)",
      dataIndex: "voice_id_2",
      key: "voice_id_2",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Open AI Model",
      dataIndex: "open_ai_model",
      key: "open_ai_model",
      render: (text) => <div>{text}</div>,
    },
    /*{
     title: "Prohibited Topics",
     dataIndex: "prohibited_topics",
     key: "prohibited_topics"
    },*/
    {
      title: "Profile Picture",
      dataIndex: "profilePic",
      key: "profilePic",
      render: (text) => (
        <img
          src={text}
          alt={text ? "Profile picture" : "No Profile Pic"}
          style={{ width: "50px", height: "auto" }}
        />
      ),
    },
  ];

  useEffect(() => {
    getUserData();
    getImageServicesData();
  }, []);

  useEffect(() => {
    console.log("Data", data);
    if (!data) return;

    editSettings(data[0]);
  }, [data]);

  const getUserData = async () => {
    await customAxios.get(SERVER_URL + "/influencer").then(function (res) {
      // Only keep the influencer with the id we are looking for

      const filteredData = res.data.filter(
        (influencer) => influencer.id == influencerId
      );
      setData(filteredData);

      setLoading(false);
    });
  };

  const getImageServicesData = async () => {
    await customAxios
      .get(
        SERVER_URL +
        "/influencer/image_generation_service?influencer_id=" +
        influencerId
      )
      .then(function (res) {
        // Only keep the influencer with the id we are looking for

        setImageServicesData(res.data);
      });
  };

  useEffect(() => {
    setFileUploadPath("");
  }, [fileInput]);

  useEffect(() => {
    setFileUploadPath6("");
  }, [fileInput6]);

  useEffect(() => {
    setFileUploadPath7("");
  }, [fileInput7]);

  useEffect(() => {
    setFileUploadPath8("");
  }, [fileInput8]);

  const uploadImageHandler = async (
    fileInputVariable,
    setFileUploadPathCallback,
    setFileUploadLoadingCallback
  ) => {
    if (!fileInputVariable) {
      messageApi.error("Invalid file!");
      return;
    }
    try {
      console.log("Uploading file...");
      setFileUploadLoadingCallback(true);

      // Create a new FormData instance
      const formData = new FormData();

      // Append the file to the FormData instance
      formData.append("file", fileInputVariable.originFileObj);
      formData.append("name", fileInputVariable.name);
      formData.append("type", fileInputVariable.type);

      // POST the formData instance to the server
      let response = await customAxios.post(
        SERVER_URL + "/upload/url",
        formData,
        {
          headers: {
            "Content-Type": fileInputVariable.type,
          },
        }
      );

      // The file upload is complete
      console.log("File uploaded successfully", response.data.file_path);
      setFileUploadPathCallback(response.data.file_path);
      messageApi.success("File upload success!");
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    } finally {
      setFileUploadLoadingCallback(false);
    }
  };

  const onChange = (key) => {
    // console.log(key);
  };

  const NotificationSettings = () => {
    return (
      <>
        <h2>Push Notification Settings:</h2>
        <h3>1. Push Notification</h3>
        <Form.Item
          {...formItemLayout}
          name="notification_frequency"
          label="First Push Notification Afer X Hours"
          rules={[
            {
              required: true,
              message: "Please input push notification frequency",
            },
          ]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification frequency"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="notification_title_1"
          label="First Push Notification Title"
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification title"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="notification_message"
          label="First Push Notification Message"
          rules={[
            {
              required: true,
              message: "Please input push notification message",
            },
          ]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification message)"
          />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_prescriptive_mode_1"*/}
        {/*  label="First Notification Prescriptive Mode"*/}
        {/*>*/}
        {/*  <select name="notification_prescriptive_mode_1" id="notification_prescriptive_mode_1" style={{width: "100%"}}>*/}
        {/*    <option value="1">True</option>*/}
        {/*    <option value="0">False</option>*/}
        {/*  </select>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_prompt_1"*/}
        {/*  label="First Notification Prompt"*/}
        {/*  rules={[{required: false, message: "Please input notification prompt"}]}*/}
        {/*>*/}
        {/*  <Input type="text" min={1} placeholder="Please input notification prompt"/>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_image_1"*/}
        {/*  label="First Notification Image"*/}
        {/*  rules={[{required: false, message: "Please input notification_image_1)"}]}*/}
        {/*>*/}
        {/*  <Space.Compact block>*/}
        {/*    <Input*/}
        {/*      type="file"*/}
        {/*      placeholder="Select file"*/}
        {/*      accept="image/*"*/}
        {/*      style={{width: "80%"}}*/}
        {/*      onChange={(e) => {*/}
        {/*        if (e.target.files && e.target.files.length > 0) {*/}
        {/*          setFileInput6(e.target.files[0]);*/}
        {/*        }*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <Button*/}
        {/*      type="primary"*/}
        {/*      onClick={() => uploadImageHandler(fileInput6, setFileUploadPath6, setFileUploadLoading6)}*/}
        {/*      style={{width: "20%", marginTop: "0px"}}*/}
        {/*      loading={fileUploadLoading6}*/}
        {/*      disabled={fileUploadPath6}*/}
        {/*    >*/}
        {/*      {fileUploadPath6.length > 0 ? "Uploaded" : "Upload"}*/}
        {/*    </Button>*/}
        {/*  </Space.Compact>*/}
        {/*</Form.Item>*/}

        <h3>2. Push Notification</h3>
        <Form.Item
          {...formItemLayout}
          name="notification_frequency_2"
          label="Second Push Notification Afer X Hours"
          rules={[
            {
              required: true,
              message: "Please input push notification frequency",
            },
          ]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification frequency"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="notification_title_2"
          label="Second Push Notification Title"
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification title"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="notification_message_2"
          label="Second Push Notification Message"
          rules={[
            {
              required: true,
              message: "Please input push notification message",
            },
          ]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification message)"
          />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_prescriptive_mode_2"*/}
        {/*  label="Second Notification Prescriptive Mode"*/}
        {/*>*/}
        {/*  <select name="notification_prescriptive_mode_2" id="notification_prescriptive_mode_2" style={{width: "100%"}}>*/}
        {/*    <option value="1">True</option>*/}
        {/*    <option value="0">False</option>*/}
        {/*  </select>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_prompt_2"*/}
        {/*  label="Second Notification Prompt"*/}
        {/*  rules={[{required: false, message: "Please input notification prompt"}]}*/}
        {/*>*/}
        {/*  <Input type="text" min={1} placeholder="Please input notification prompt"/>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_image_2"*/}
        {/*  label="Second Notification Image"*/}
        {/*  rules={[{required: false, message: "Please input notification_image_2)"}]}*/}
        {/*>*/}
        {/*  <Space.Compact block>*/}
        {/*    <Input*/}
        {/*      type="file"*/}
        {/*      placeholder="Select file"*/}
        {/*      accept="image/*"*/}
        {/*      style={{width: "80%"}}*/}
        {/*      onChange={(e) => {*/}
        {/*        if (e.target.files && e.target.files.length > 0) {*/}
        {/*          setFileInput7(e.target.files[0]);*/}
        {/*        }*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <Button*/}
        {/*      type="primary"*/}
        {/*      onClick={() => uploadImageHandler(fileInput7, setFileUploadPath7, setFileUploadLoading7)}*/}
        {/*      style={{width: "20%", marginTop: "0px"}}*/}
        {/*      loading={fileUploadLoading7}*/}
        {/*      disabled={fileUploadPath7}*/}
        {/*    >*/}
        {/*      {fileUploadPath7.length > 0 ? "Uploaded" : "Upload"}*/}
        {/*    </Button>*/}
        {/*  </Space.Compact>*/}
        {/*</Form.Item>*/}

        <h3>3. Push Notification</h3>
        <Form.Item
          {...formItemLayout}
          name="notification_frequency_3"
          label="Third Push Notification Afer X Hours"
          rules={[
            {
              required: true,
              message: "Please input push notification frequency",
            },
          ]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification frequency"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="notification_title_3"
          label="Third Push Notification Title"
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification title"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="notification_message_3"
          label="Third Push Notification Message"
          rules={[
            {
              required: true,
              message: "Please input push notification message)",
            },
          ]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input push notification message)"
          />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_prescriptive_mode_3"*/}
        {/*  label="Third Notification Prescriptive Mode"*/}
        {/*>*/}
        {/*  <select name="notification_prescriptive_mode_3" id="notification_prescriptive_mode_3" style={{width: "100%"}}>*/}
        {/*    <option value="1">True</option>*/}
        {/*    <option value="0">False</option>*/}
        {/*  </select>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_prompt_3"*/}
        {/*  label="Third Notification Prompt"*/}
        {/*  rules={[{required: false, message: "Please input notification prompt"}]}*/}
        {/*>*/}
        {/*  <Input type="text" min={1} placeholder="Please input notification prompt"/>*/}
        {/*</Form.Item>*/}

        {/*<Form.Item*/}
        {/*  {...formItemLayout}*/}
        {/*  name="notification_image_3"*/}
        {/*  label="Third Notification Image"*/}
        {/*  rules={[{required: false, message: "Please input notification_image_3)"}]}*/}
        {/*>*/}
        {/*  <Space.Compact block>*/}
        {/*    <Input*/}
        {/*      type="file"*/}
        {/*      placeholder="Select file"*/}
        {/*      accept="image/*"*/}
        {/*      style={{width: "80%"}}*/}
        {/*      onChange={(e) => {*/}
        {/*        if (e.target.files && e.target.files.length > 0) {*/}
        {/*          setFileInput8(e.target.files[0]);*/}
        {/*        }*/}
        {/*      }*/}
        {/*      }*/}
        {/*    />*/}
        {/*    <Button*/}
        {/*      type="primary"*/}
        {/*      onClick={() => uploadImageHandler(fileInput8, setFileUploadPath8, setFileUploadLoading8)}*/}
        {/*      style={{width: "20%", marginTop: "0px"}}*/}
        {/*      loading={fileUploadLoading8}*/}
        {/*      disabled={fileUploadPath8}*/}
        {/*    >*/}
        {/*      {fileUploadPath8.length > 0 ? "Uploaded" : "Upload"}*/}
        {/*    </Button>*/}
        {/*  </Space.Compact>*/}
        {/*</Form.Item>*/}
      </>
    );
  };

  const WelcomeMessageSettings = () => {
    return (
      <>
        <h2>Welcome Message Settings:</h2>
        <Card
          type="inner"
          title="Welcome Message Option 1"
          style={{ marginBottom: "15px" }}
        >
          <Form.Item
            {...formItemLayout}
            name="welcome_message1"
            label="Message 1"
            rules={[
              { required: false, message: "Please input a welcome message" },
            ]}
          >
            <Input
              type="text"
              min={1}
              placeholder="Please input a Welcome Message"
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="welcome_message1_image"
            rules={[
              {
                required: false,
                message: "Please input a welcome message image)",
              },
            ]}
            style={{ display: "flex", width: "100%" }}
          >
            <Upload
              name="welcome_message1_image"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={fileInput1}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setFileInput1(e.fileList);
                setFileUploadPath1("");
              }}
              onRemove={(e) => {
                setFileInput1([]);
                setFileUploadPath1("");
              }}
            >
              <Button icon={<UploadOutlined />}>Click to select</Button>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              uploadImageHandler(
                fileInput1.length > 0 ? fileInput1[0] : null,
                setFileUploadPath1,
                setFileUploadLoading1
              )
            }
            style={{ width: "20%", marginTop: "0px" }}
            loading={fileUploadLoading1}
            disabled={fileUploadPath1}
          >
            {fileUploadPath1.length > 0 ? "Uploaded" : "Upload"}
          </Button>
        </Card>

        <Card
          type="inner"
          title="Welcome Message Option 2"
          style={{ marginBottom: "15px" }}
        >
          <Form.Item
            {...formItemLayout}
            name="welcome_message2"
            label="Message 2"
            rules={[
              { required: false, message: "Please input a welcome message" },
            ]}
          >
            <Input
              type="text"
              min={1}
              placeholder="Please input a Welcome Message"
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="welcome_message2_image"
            rules={[
              {
                required: false,
                message: "Please input a welcome message image)",
              },
            ]}
            style={{ display: "flex", width: "100%" }}
          >
            <Upload
              name="welcome_message2_image"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={fileInput2}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setFileInput2(e.fileList);
                setFileUploadPath2("");
              }}
              onRemove={(e) => {
                setFileInput2([]);
                setFileUploadPath2("");
              }}
            >
              <Button icon={<UploadOutlined />}>Click to select</Button>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              uploadImageHandler(
                fileInput2.length > 0 ? fileInput2[0] : null,
                setFileUploadPath2,
                setFileUploadLoading2
              )
            }
            style={{ width: "20%", marginTop: "0px" }}
            loading={fileUploadLoading2}
            disabled={fileUploadPath2}
          >
            {fileUploadPath2.length > 0 ? "Uploaded" : "Upload"}
          </Button>
        </Card>

        <Card
          type="inner"
          title="Welcome Message Option 3"
          style={{ marginBottom: "15px" }}
        >
          <Form.Item
            {...formItemLayout}
            name="welcome_message3"
            label="Message 3"
            rules={[
              { required: false, message: "Please input a welcome message" },
            ]}
          >
            <Input
              type="text"
              min={1}
              placeholder="Please input a Welcome Message"
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="welcome_message3_image"
            rules={[
              {
                required: false,
                message: "Please input a welcome message image)",
              },
            ]}
            style={{ display: "flex", width: "100%" }}
          >
            <Upload
              name="welcome_message3_image"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={fileInput3}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setFileInput3(e.fileList);
                setFileUploadPath3("");
              }}
              onRemove={(e) => {
                setFileInput3([]);
                setFileUploadPath3("");
              }}
            >
              <Button icon={<UploadOutlined />}>Click to select</Button>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              uploadImageHandler(
                fileInput3.length > 0 ? fileInput3[0] : null,
                setFileUploadPath3,
                setFileUploadLoading3
              )
            }
            style={{ width: "20%", marginTop: "0px" }}
            loading={fileUploadLoading3}
            disabled={fileUploadPath3}
          >
            {fileUploadPath3.length > 0 ? "Uploaded" : "Upload"}
          </Button>
        </Card>

        <Card
          type="inner"
          title="Welcome Message Option 4"
          style={{ marginBottom: "15px" }}
        >
          <Form.Item
            {...formItemLayout}
            name="welcome_message4"
            label="Message 4"
            rules={[
              { required: false, message: "Please input a welcome message" },
            ]}
          >
            <Input
              type="text"
              min={1}
              placeholder="Please input a Welcome Message"
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="welcome_message4_image"
            rules={[
              {
                required: false,
                message: "Please input a welcome message image)",
              },
            ]}
            style={{ display: "flex", width: "100%" }}
          >
            <Upload
              name="welcome_message4_image"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={fileInput4}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setFileInput4(e.fileList);
                setFileUploadPath4("");
              }}
              onRemove={(e) => {
                setFileInput4([]);
                setFileUploadPath4("");
              }}
            >
              <Button icon={<UploadOutlined />}>Click to select</Button>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              uploadImageHandler(
                fileInput4.length > 0 ? fileInput4[0] : null,
                setFileUploadPath4,
                setFileUploadLoading4
              )
            }
            style={{ width: "20%", marginTop: "0px" }}
            loading={fileUploadLoading4}
            disabled={fileUploadPath4}
          >
            {fileUploadPath4.length > 0 ? "Uploaded" : "Upload"}
          </Button>
        </Card>

        <Card
          type="inner"
          title="Welcome Message Option 5"
          style={{ marginBottom: "15px" }}
        >
          <Form.Item
            {...formItemLayout}
            name="welcome_message5"
            label="Message 5"
            rules={[
              { required: false, message: "Please input a welcome message" },
            ]}
          >
            <Input
              type="text"
              min={1}
              placeholder="Please input a Welcome Message"
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="welcome_message5_image"
            rules={[
              {
                required: false,
                message: "Please input a welcome message image)",
              },
            ]}
            style={{ display: "flex", width: "100%" }}
          >
            <Upload
              name="welcome_message5_image"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={fileInput5}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setFileInput5(e.fileList);
                setFileUploadPath5("");
              }}
              onRemove={(e) => {
                setFileInput5([]);
                setFileUploadPath5("");
              }}
            >
              <Button icon={<UploadOutlined />}>Click to select</Button>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              uploadImageHandler(
                fileInput5.length > 0 ? fileInput5[0] : null,
                setFileUploadPath5,
                setFileUploadLoading5
              )
            }
            style={{ width: "20%", marginTop: "0px" }}
            loading={fileUploadLoading5}
            disabled={fileUploadPath5}
          >
            {fileUploadPath5.length > 0 ? "Uploaded" : "Upload"}
          </Button>
        </Card>
      </>
    );
  };

  const Other = (formItemLayout) => {
    return (
      <>
        <h2>Other:</h2>
        <Form.Item
          {...formItemLayout}
          name="usersent_noCaption_Image_message"
          label="User Sent No Caption Image Message"
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input User Sent No Caption Image Message"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="startOver_hintMSG"
          label="Start Over Hint Message"
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input Start Over Hint Message"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="pwa_hint_msg"
          label="PWA Hint Message"
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input PWA Hint Message"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="notifications_hint_msg"
          label="Notifications Hint Message"
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input Notifications Hint Message"
          />
        </Form.Item>
        <Card
          type="inner"
          title="Twitter Preview Picture"
          style={{ marginBottom: "15px" }}
        >
          <Form.Item
            {...formItemLayout}
            name="twitter_preview_pic"
            rules={[
              {
                required: false,
                message: "Please input a twitter preview image)",
              },
            ]}
            style={{ display: "flex", width: "100%" }}
          >
            <Upload
              name="twitter_preview_pic"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={fileInput6}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setFileInput6(e.fileList);
                setFileUploadPath6("");
              }}
              onRemove={(e) => {
                setFileInput6([]);
                setFileUploadPath6("");
              }}
            >
              <Button icon={<UploadOutlined />}>Click to select</Button>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              uploadImageHandler(
                fileInput6.length > 0 ? fileInput6[0] : null,
                setFileUploadPath6,
                setFileUploadLoading6
              )
            }
            style={{ width: "20%", marginTop: "0px" }}
            loading={fileUploadLoading6}
            disabled={fileUploadPath6}
          >
            {fileUploadPath6.length > 0 ? "Uploaded" : "Upload"}
          </Button>
        </Card>

        {/* <Form.Item
          {...formItemLayout}
          name="prohibited_topics"
          label="Prohibited Topics"
          rules={[{ required: false, message: 'Please input prohibited topics' }]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input prohibited topics"
            onChange={handleProhibitedTopicsChange}
          />
        </Form.Item> */}
      </>
    );
  };

  const Moderation = () => {
    return (
      <>
        <h2>Moderation:</h2>
        <Form.Item
          {...formItemLayout}
          name="image_generation_banned_words"
          label="Image Generation Influencer Specific Banned Words (will be appended with the global banned words)"
          rules={[
            {
              required: false,
              message: "Please input image_generation_banned_words",
            },
          ]}
        >
          <Input
            type="text"
            min={1}
            placeholder="Please input the words that should be banned for Image Generation prompts. Format: word1, word2, word3, ..."
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="enable_moderation_engine"
          label="Enable Hard Moderation Engine [OpenAI Moderation Endpoint]"
        >
          <select
            onChange={(e) => setEnable_moderation_engine(e.target.value)}
            name="enable_moderation_engine"
            id="enable_moderation_engine"
            style={{
              width: "100%",
              color: enable_moderation_engine == "1" ? "black" : "red",
            }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <p>
          <i>
            If enabled Moderation will trigger the "Against Terms Of Service
            System" Message
          </i>
        </p>
        <Form.Item
          {...formItemLayout}
          name="enable_intent_engine"
          label="Enable Intent Engine [Controls both Soft Moderation and Image/Link Search]"
        >
          <select
            defaultValue={enable_intent_engine}
            onChange={(e) => setEnable_intent_engine(e.target.value)}
            name="enable_intent_engine"
            id="enable_intent_engine"
            style={{
              width: "100%",
              color: enable_intent_engine == "1" ? "black" : "red",
            }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <p>
          <i>
            Intent engine prompt: If user's input discusses or asks about this
            topics, set true, if not, set false -> Topics = Past Reputation
            Damage, Controversial, Politics, Religion, ...{" "}
          </i>
        </p>
        <p>
          <i>
            if the user is asking for an image, summarize the type of image
            requested in 5 words or less. If no image is requested, write 'NA'.
          </i>
        </p>
        <p>
          <i>
            &#123;"role": "user", "content": 'User Input: Do you have any
            favorite vacation spots?, last user messages: How was your day?;,
            Person Payload: &#123;[&#123;"link":
            "https://example.com/video1.mp4", "type": "video", "tag":
            "Vacations, Sunny, Beach"&#125;,&#123;"link":
            "https://example.com/image1.jpg", "type": "image", "tag": "Coffee,
            Cafe, Spring"&#125;]&#125;'&#125;,
          </i>
        </p>
        <p>
          <i>
            &#123;"role": "assistant", "content": '&#123;"user_input":
            "vacation", "link": "https://example.com/video1.mp4", "type":
            "video", "tag": "Vacations"&#125;' &#125;,
          </i>
        </p>
        {enable_moderation_engine == "0" && enable_intent_engine == "1" && (
          <div>
            <p style={{ color: " red" }}>
              Enabling the Intent Engine when the moderation Engine is disabled
              could lead to a ban from ChatGPT!
            </p>
          </div>
        )}

        <h3>Hard Moderation:</h3>
        <Form.Item {...formItemLayout} name="flag_sexual" label="Flag Sexual">
          <select name="flag_sexual" id="flag_sexual" style={{ width: "100%" }}>
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_sexual"
          label="Prompt Sexual"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item {...formItemLayout} name="flag_hate" label="Flag Hate">
          <select name="flag_hate" id="flag_hate" style={{ width: "100%" }}>
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item {...formItemLayout} name="prompt_hate" label="Prompt Hate">
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_harassment"
          label="Flag Harassment"
        >
          <select
            name="flag_harassment"
            id="flag_harassment"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_harassment"
          label="Prompt Harassment"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_self_harm"
          label="Flag Self Harm"
        >
          <select
            name="flag_self_harm"
            id="flag_self_harm"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_self_harm"
          label="Prompt Self Harm"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_sexual_minors"
          label="Flag Sexual Minors"
        >
          <select
            name="flag_sexual_minors"
            id="flag_sexual_minors"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_sexual_minors"
          label="Prompt Sexual Minors"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_hate_threatening"
          label="Flag Hate Threatening"
        >
          <select
            name="flag_hate_threatening"
            id="flag_hate_threatening"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_hate_threatening"
          label="Prompt Hate Threatening"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_violence_graphic"
          label="Flag Violence Graphic"
        >
          <select
            name="flag_violence_graphic"
            id="flag_violence_graphic"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_violence_graphic"
          label="Prompt Violence Graphic"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_flag_self_harm_intent"
          label="Flag Self Harm Intent"
        >
          <select
            name="flag_flag_self_harm_intent"
            id="flag_flag_self_harm_intent"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_flag_self_harm_intent"
          label="Prompt Self Harm Intent"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_self_harm_instructions"
          label="Flag Self Harm Instructions"
        >
          <select
            name="flag_self_harm_instructions"
            id="flag_self_harm_instructions"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_self_harm_instructions"
          label="Prompt Self Harm Instructions"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_harassment_threatening"
          label="Flag Harassment Threatening"
        >
          <select
            name="flag_harassment_threatening"
            id="flag_harassment_threatening"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_harassment_threatening"
          label="Prompt Harassment Threatening"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_violence"
          label="Flag Violence"
        >
          <select
            name="flag_violence"
            id="flag_violence"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_violence"
          label="Prompt Violence"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>

        <h3>Soft Moderation:</h3>
        <Form.Item
          {...formItemLayout}
          name="flag_past_reputation_damage"
          label="Flag Past Reputation Damage"
        >
          <select
            name="flag_past_reputation_damage"
            id="flag_past_reputation_damage"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_past_reputation_damage"
          label="Prompt Past Reputation Damage"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_controversial"
          label="Flag Controversial"
        >
          <select
            name="flag_controversial"
            id="flag_controversial"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_controversial"
          label="Prompt Controversial"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_politics"
          label="Flag Politics"
        >
          <select
            name="flag_politics"
            id="flag_politics"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_politics"
          label="Prompt Politics"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="flag_religion"
          label="Flag Religion"
        >
          <select
            name="flag_religion"
            id="flag_religion"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="prompt_religion"
          label="Prompt Religion"
        >
          <Input type="text" min={1} placeholder="Please input a prompt" />
        </Form.Item>
      </>
    );
  };

  const items = [
    {
      key: "1",
      label: "Basic Functionalities",
      forceRender: true,
      children: <BasicFunctionalities formItemLayout={formItemLayout} />,
    },
    {
      key: "2",
      label: "Secret Menu",
      forceRender: true,
      children: <SecretMenu formItemLayout={formItemLayout} />,
    },
    {
      key: "3",
      label: "Push Notification Settings",
      forceRender: true,
      children: <NotificationSettings />,
    },
    {
      key: "4",
      label: "Tip Settings",
      forceRender: true,
      children: <TipSettings formItemLayout={formItemLayout} />,
    },
    {
      key: "5",
      label: "Welcome Message Settings",
      forceRender: true,
      children: <WelcomeMessageSettings />,
    },
    {
      key: "6",
      label: "Wishlist Settings",
      forceRender: true,
      children: <WishlistSettings formItemLayout={formItemLayout} />,
    },
    {
      key: "7",
      label: "Moderation",
      forceRender: true,
      children: <Moderation />,
    },
    {
      key: "8",
      label: "Model Settings",
      forceRender: true,
      children: (
        <ModelSettings
          formItemLayout={formItemLayout}
          aiEngine={aiEngine}
          setAiEngine={setAiEngine}
        />
      ),
    },
    /*{
      key: "9",
      label: "Astria Image Generation Settings",
      forceRender: true,
      children: <AstriaImageGenerationSettings formItemLayout={formItemLayout} />,
    },
    {
      key: "10",
      label: "SeaArt Image Generation Settings",
      forceRender: true,
      children: <SeaArtImageGenerationSettings formItemLayout={formItemLayout} />,
    },*/
    /*{
      key: "11",
      label: "Diffus Image Generation Settings",
      forceRender: true,
      children: <DiffusImageGenerationSettings formItemLayout={formItemLayout} />,
    },*/
    {
      key: (12 + imageServicesData.length).toString(),
      label: "Image Generation Services",
      forceRender: true,
      children: (
        <ImageGenerationServices
          formItemLayout={formItemLayout}
          influencer_id={influencerId}
          imageServicesData={imageServicesData}
        />
      ),
    },
    {
      key: (13 + imageServicesData.length).toString(),
      label: "Voice Generation Settings",
      forceRender: true,
      children: <VoiceGenerationSettings formItemLayout={formItemLayout} />,
    },
    {
      key: (14 + imageServicesData.length).toString(),
      label: "Prescriptive Mode",
      forceRender: true,
      children: <PrescriptiveMode formItemLayout={formItemLayout} />,
    },
    // {
    //   key: (15 + imageServicesData.length).toString(),
    //   label: "Regeneration Message Mode Settings",
    //   forceRender: true,
    //   children: <RegenerationMessageModeSettings formItemLayout={formItemLayout} />,
    // },
    {
      key: (15 + imageServicesData.length).toString(),
      label: "Bonus Content",
      forceRender: true,
      children: <BonusContent formItemLayout={formItemLayout} />,
    },
    {
      key: (16 + imageServicesData.length).toString(),
      label: "Other",
      forceRender: true,
      children: <Other formItemLayout={formItemLayout} />,
    },
  ];

  let temp = imageServicesData.map((imageService, index) => {
    return {
      key: (11 + index).toString(),
      label: imageService.name + " Image Generation Settings",
      forceRender: true,
      children: <DiffusImageGenerationSettings formItemLayout={imageService} />,
    };
  });

  // Concatenate the two arrays after "10"
  items.splice(10, 0, ...temp);

  return (
    <div>
      {contextHolder}

      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <div>
          <div>
            <Table
              style={{ marginTop: "8px" }}
              columns={influencerColumns}
              dataSource={data}
              rowKey="id"
            />
          </div>

          <div>
            <Form form={form} name="dynamic_rule" layout="vertical">
              <Collapse items={items} size="large" onChange={onChange} />

              <Form.Item style={{}}>
                <Button type="default" onClick={() => window.location.reload()}>
                  Cancel
                </Button>
                <Button type="primary" onClick={handleSettingsOk}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfluencerSettings;

const SelectBefore = (props) => {
  return (
    <Select
      defaultValue="http://"
      value={props.linkPrefix}
      onChange={(val) => props.setLinkPrefix(val)}
    >
      <Select.Option value="http://">http://</Select.Option>
      <Select.Option value="https://">https://</Select.Option>
    </Select>
  );
};
